import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import YearMonthForm from './dateRangePicker/yearmonthPicker';
import { setWoCustomDateRange } from '../../store/cutsmart/cutsmartstate';
import { OrderTimeFilterOptions } from '../../store/order/orderstate/orderstate';
import Dropdown from 'react-bootstrap/Dropdown';
import { CalendarIcon, ChevronDownIcon } from '../order/listColumnFilter/icon';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);

const PageDefaultTimeSelector = ({ preset, onSelectCb }) => {
  const [is_dropdown_open, setDropdownState] = useState(false);

  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          is_dropdown_open ? 'active' : ''
        }`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );
  const onDropdownToggle = status => {
    setDropdownState(status);
  };
  return (
    <Dropdown
      className='page-size-selector'
      onToggle={onDropdownToggle}
      drop={'down'}
      flip={'false'}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        className='d-flex align-items-center'
        id='default-time-pick'
      >
        <CalendarIcon />
        <span className='btn-label text-primary ps-2 pe-2'>
          {OrderTimeFilterOptions[`${preset}`]?.text || 'Custom'}
        </span>
        <ChevronDownIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu align={{ sm: 'right' }}>
        {Object.values(OrderTimeFilterOptions)
          .filter(val => val.key !== 'custom')
          .map(time_option => (
            <Dropdown.Item
              active={time_option.key === preset}
              key={time_option.key}
              eventKey={time_option.key}
              className='text-uppercase'
              onClick={() => onSelectCb(time_option.key)}
            >
              {time_option.text}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

PageDefaultTimeSelector.propTypes = {
  preset: PropTypes.string.isRequired,
  onSelectCb: PropTypes.func.isRequired,
};
const DateRangePickers = ({ from_date, to_date, preset, onDateChangeCb }) => {
  const [month, onMonthChange] = useState(fromMonth);
  const [fromDate, setFromDate] = useState(from_date);
  const [toDate, setToDate] = useState(to_date);
  const handleYearMonthChange = month => {
    onMonthChange(month);
  };
  useEffect(() => {
    switch (preset) {
      case 'today':
        setFromDate(moment().startOf('day').toDate());
        break;
      case 'yesterday':
        setFromDate(moment().startOf('day').add(-1, 'day').toDate());
        break;
      case 'this_week':
        setFromDate(moment().startOf('week').toDate());
        break;
      case 'this_month':
        setFromDate(moment().startOf('month').toDate());
        break;
      case 'last_two_months':
        setFromDate(moment().startOf('month').add(-2, 'month').toDate());
        break;
      case 'last_six_months':
        setFromDate(moment().startOf('month').add(-6, 'month').toDate());
        break;
      case 'this_year':
        setFromDate(moment().startOf('year').toDate());
        break;
      case 'last_year':
        setFromDate(moment().startOf('year').add(-1, 'year').toDate());
        break;
      case 'last_2_year':
        setFromDate(moment().startOf('year').add(-1, 'year').toDate());
        break;
      case 'begining':
        setFromDate(moment('20200101').toDate());
        break;
      case 'custom':
        setFromDate(from_date);
        break;
      default:
        setFromDate(from_date);
    }
  }, [from_date, preset]);
  useEffect(() => {
    switch (preset) {
      case 'yesterday':
        setToDate(moment().startOf('day').add(-1, 'day').toDate());
        break;
      case 'last_year':
        setToDate(moment().startOf('year').toDate());
        break;
      case 'custom':
        setToDate(to_date);
        break;
      default:
        setToDate(moment().startOf('day').toDate());
    }
  }, [to_date, preset]);
  const onFormDateChange = useCallback(
    date => {
      onDateChangeCb(date, toDate, 'custom');
    },
    [toDate, onDateChangeCb],
  );
  const onToDateChange = useCallback(
    date => {
      onDateChangeCb(fromDate, date, 'custom');
    },
    [fromDate, onDateChangeCb],
  );

  const modifiers = { start: new Date(from_date), end: new Date(to_date) };
  return (
    <Fragment>
      <div className='date-range-picker-wrapper'>
        <div className='d-flex align-items-center'>
          <div className='date-range-from-wrapper d-flex'>
            <div className='title-sm'>From</div>
            <div className='title-sm ps-2'>
              <DayPickerInput
                onDayChange={onFormDateChange}
                format='DD-MM-YYYY'
                placeholder='Select from date'
                formatDate={formatDate}
                parseDate={parseDate}
                value={new Date(fromDate)}
                dayPickerProps={{
                  month: month,
                  fromMonth: fromMonth,
                  toMonth: toMonth,
                  modifiers,
                  disabledDays: { after: new Date(toDate) },
                  captionElement: (
                    { date, localeUtils }, //eslint-disable-line
                  ) => (
                    <YearMonthForm
                      date={date}
                      localeUtils={localeUtils}
                      onChange={handleYearMonthChange}
                    />
                  ),
                }}
              />
            </div>
          </div>
          <div className='date-range-from-wrapper d-flex'>
            <div className='title-sm'>to</div>
            <div className='title-sm ps-2'>
              <DayPickerInput
                onDayChange={onToDateChange}
                format='DD-MM-YYYY'
                placeholder='Select to date'
                value={new Date(toDate)}
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  month: month,
                  fromMonth: fromMonth,
                  toMonth: toMonth,
                  modifiers,
                  selectedDays: [
                    new Date(toDate),
                    { from: new Date(fromDate), to: new Date(toDate) },
                  ],
                  disabledDays: {
                    before: new Date(fromDate),
                    after: new Date(),
                  },
                  // eslint-disable-next-line sonarjs/no-identical-functions
                  captionElement: ({ date, localeUtils }) => (
                    <YearMonthForm
                      date={date}
                      localeUtils={localeUtils}
                      onChange={handleYearMonthChange}
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <PageDefaultTimeSelector
          onSelectCb={val => {
            onDateChangeCb(from_date, to_date, val);
          }}
          preset={preset}
        />
      </div>
    </Fragment>
  );
};

DateRangePickers.propTypes = {};

const DateRangePicker = ({ from_date, to_date, preset }) => {
  const dispatch = useDispatch();
  const [month, onMonthChange] = useState(fromMonth);
  const [fromDate, setFromDate] = useState(new Date(from_date));
  const [toDate, setToDate] = useState(new Date(to_date));

  const handleYearMonthChange = month => {
    onMonthChange(month);
  };
  useEffect(() => {
    const presetOption = OrderTimeFilterOptions[`${preset}`];
    if (presetOption) {
      const newFromDate = new Date(presetOption.from);
      const newToDate = new Date(presetOption.to);

      setFromDate(newFromDate);
      setToDate(newToDate);

      dispatch(
        setWoCustomDateRange({ from: newFromDate, to: newToDate, preset }),
      );
    }
  }, [preset, dispatch]);

  const onFormDateChange = useCallback(
    date => {
      dispatch(
        setWoCustomDateRange({ from: date, to: toDate, preset: 'custom' }),
      );
    },
    [toDate, dispatch],
  );
  const onToDateChange = useCallback(
    date => {
      dispatch(
        setWoCustomDateRange({ from: fromDate, to: date, preset: 'custom' }),
      );
    },
    [fromDate, dispatch],
  );

  const modifiers = { start: new Date(from_date), end: new Date(to_date) };
  return (
    <Fragment>
      <div className='date-range-picker-wrapper'>
        <div className='d-flex align-items-center'>
          <div className='date-range-from-wrapper d-flex'>
            <div className='title-sm'>From</div>
            <div className='title-sm ps-2'>
              <DayPickerInput
                onDayChange={onFormDateChange}
                format='DD-MM-YYYY'
                placeholder='Select from date'
                formatDate={formatDate}
                parseDate={parseDate}
                value={fromDate}
                dayPickerProps={{
                  month: month,
                  fromMonth: fromMonth,
                  toMonth: toMonth,
                  modifiers,
                  disabledDays: { after: new Date(toDate) },
                  captionElement: (
                    { date, localeUtils }, //eslint-disable-line
                  ) => (
                    <YearMonthForm
                      date={date}
                      localeUtils={localeUtils}
                      onChange={handleYearMonthChange}
                    />
                  ),
                }}
              />
            </div>
          </div>
          <div className='date-range-from-wrapper d-flex'>
            <div className='title-sm'>To</div>
            <div className='title-sm ps-2'>
              <DayPickerInput
                onDayChange={onToDateChange}
                format='DD-MM-YYYY'
                placeholder='Select to date'
                value={toDate}
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  month: month,
                  fromMonth: fromMonth,
                  toMonth: toMonth,
                  modifiers,
                  selectedDays: [toDate, { from: fromDate, to: toDate }],
                  disabledDays: {
                    before: fromDate,
                    after: new Date(),
                  },
                  captionElement: (
                    { date, localeUtils }, //eslint-disable-line
                  ) => (
                    <YearMonthForm
                      date={date}
                      localeUtils={localeUtils}
                      onChange={handleYearMonthChange}
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <PageDefaultTimeSelector
          onSelectCb={val => {
            dispatch(
              setWoCustomDateRange({ from: fromDate, to: toDate, preset: val }),
            );
          }}
          preset={preset}
        />
      </div>
    </Fragment>
  );
};

DateRangePicker.propTypes = {
  from_date: PropTypes.instanceOf(Date).isRequired,
  to_date: PropTypes.instanceOf(Date).isRequired,
  preset: PropTypes.string.isRequired,
};

export { DateRangePicker, DateRangePickers };
