import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import useMobile from '../../hooks/useMobile';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import CloseIcon from '../../assets/icons/icon-cancel.svg';
import ImageListModal from './image-list-modal';
import { RefreshIcon } from '../../assets/icons/nav-icons';
import moment from 'moment/moment';
import { TrackTraceSuccessIcon } from '../../assets/icons/icon-tracktrace';
import Active from '../../assets/icons/activeStatus.svg';
import { MinusCircleIcon } from '../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { AddImageModal, ReasonDetailModal } from './reject-reason-modals';
import {
  filterReasons,
  mapReasons,
} from '../../helpers/tracktrace/rejectReasonsHelper';
const ScanHistoryDetail = memo(({ scanRecord }) => {
  return (
    <div className='row rubik-font ' style={{ fontWeight: 'normal' }}>
      <div className={'d-flex flex-wrap track-trace-scanned-on'}>
        <div>Last Scanned on</div>
        <div className='font-bold px-1' style={{ fontWeight: 'bold' }}>
          {moment(scanRecord.scanned_on).format('DD MMM hh:mm a')}
        </div>
        <div className='px-1'> By </div>
        <div className='font-bold ' style={{ fontWeight: 'bold' }}>
          {scanRecord.user_name}
        </div>
        <div className='px-1'> @ </div>
        <div className='track-trace-scan-status'>
          {scanRecord.pod_name ? scanRecord.pod_name : scanRecord.scan_station}
        </div>
      </div>
    </div>
  );
});

const RejectReasonSelection = memo(
  ({
    groupKey,
    reason,
    rejectReasonState,
    setRejectReasonState,
    onSetReasonDetailModal,
    selected,
  }) => {
    const nonReasonDetail =
      reason?.blame_operations?.length > 0 ||
      reason?.image_reqd ||
      reason?.extra_fields?.length > 0 ||
      reason?.rework_operations?.length > 0;
    const removeRejectReason = e => {
      e.stopPropagation();
      const updatedState =
        rejectReasonState &&
        rejectReasonState.filter(cs => cs.groupKey !== groupKey);
      setRejectReasonState(updatedState);
    };
    const addRejectReason = () => {
      const updatedState = {
        groupKey,
        checked: true,
      };

      setRejectReasonState(prevState => [...prevState, updatedState]);
    };
    return (
      <Fragment>
        <div
          key={groupKey}
          onClick={() => {
            if (nonReasonDetail) {
              onSetReasonDetailModal(reason, groupKey);
            } else {
              addRejectReason();
            }
          }}
        >
          <div
            style={{ width: '254px', height: '60px' }}
            className='reasons-item p-3 mb-2 cursor-pointer bg-white rounded d-flex justify-content-between align-items-center'
          >
            <div className='d-flex gap-2 align-items-center'>
              {selected && (
                <img
                  src={Active}
                  style={{ width: '16px', height: '16px' }}
                  alt='status'
                />
              )}
              <span style={{ fontWeight: 700 }} className='mb-0 font-Roboto'>
                {reason.reason}
              </span>
            </div>
            <div className='d-flex align-items-center ms-2 gap-3'>
              {selected && (
                <span onClick={removeRejectReason}>
                  <MinusCircleIcon color={'#323232CC'} />
                </span>
              )}
              {nonReasonDetail && (
                <span>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ color: '#323232CC' }}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  },
);
const FilterRejectReasons = ({
  rejectReasons,
  groupSelected,
  searchKeyword,
}) => {
  const uniqueRejectReasons = Array.from(new Set(rejectReasons.map(e => e)));

  return uniqueRejectReasons
    .flatMap(reasonGroup => reasonGroup.reasons)
    .filter(
      reasons =>
        !groupSelected || (groupSelected && reasons.group === groupSelected),
    )
    .filter(reasons =>
      reasons.reason.toLowerCase().includes(searchKeyword.toLowerCase()),
    );
};

const GroupSelector = ({
  reasons,
  groupSelected,
  setGroupSelected,
  showMore,
  setShowMore,
  totalLength,
}) => (
  <div className='col-lg-12 rubik-font d-flex flex-wrap my-3 gap-3'>
    <div
      onClick={() => setGroupSelected('')}
      className={`reasons-group-card cursor-pointer ${
        !groupSelected && 'card-selected'
      }`}
    >
      {!groupSelected && <TrackTraceSuccessIcon />} All
    </div>
    {(reasons || []).map(reasonGroup => (
      <div key={reasonGroup.group}>
        <div
          onClick={() => setGroupSelected(reasonGroup.group)}
          className={`reasons-group-card cursor-pointer ${
            reasonGroup.group === groupSelected && 'card-selected'
          }`}
        >
          {reasonGroup.group === groupSelected && <TrackTraceSuccessIcon />}
          {reasonGroup.group}
        </div>
      </div>
    ))}
    {!showMore && totalLength > 4 && (
      <div
        className='reasons-group-card cursor-pointer '
        onClick={() => setShowMore(true)}
      >
        +{totalLength - reasons.length}
      </div>
    )}
    {showMore && (
      <div
        className='reasons-group-card cursor-pointer '
        onClick={() => setShowMore(false)}
      >
        Show Less <FontAwesomeIcon icon={faChevronUp} className='ps-2' />
      </div>
    )}
  </div>
);

const ReasonList = ({
  filteredRejectReasons,
  rejectReasonState,
  setRejectReasonState,
  onSetReasonDetailModal,
}) => (
  <div
    style={{ maxHeight: '315px', overflow: 'auto' }}
    className='col-lg-12 my-2 rubik-font d-flex flex-wrap my-3 gap-3'
  >
    {filteredRejectReasons.map((reasons, i) => {
      const groupKey = `${reasons.group}|${reasons.reason}`;
      const selected = rejectReasonState.find(cs => cs.groupKey === groupKey);
      return (
        <RejectReasonSelection
          key={groupKey}
          groupKey={groupKey}
          rejectReasonState={rejectReasonState}
          setRejectReasonState={setRejectReasonState}
          reason={reasons}
          onSetReasonDetailModal={onSetReasonDetailModal}
          selected={selected}
        />
      );
    })}
  </div>
);

const RejectReasonListModal = memo(
  ({
    scanCode,
    isMobile,
    onCloseCb,
    setShowImageListModal,
    showRejectReason,
    setShowRejectReason,
    setShowImageSelectionModal,
    rejectReasons,
    setRejectReasonState,
    rejectReasonState,
    onConfirmCb,
    lastScan,
    localImages,
    setLocalImages,
    addImageModal,
    setAddImageModal,
    reasonDetailModal,
    setReasonDetailModal,
  }) => {
    const numOfReasonsSelected = Object.keys(rejectReasonState);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [groupSelected, setGroupSelected] = useState('');
    const [images, setImages] = useState([]);
    const [note, setNote] = useState('');
    const filteredRejectReasons = useCallback(
      () =>
        FilterRejectReasons({ rejectReasons, groupSelected, searchKeyword }),
      [groupSelected, rejectReasons, searchKeyword],
    );
    useEffect(() => {
      filteredRejectReasons();
    }, [filteredRejectReasons]);
    const addMore = useCallback(() => {
      setAddImageModal(prev => ({ ...prev, active: true, modal: true }));
      setShowRejectReason(false);
    }, [setShowRejectReason, setAddImageModal]);

    const addImgModalCloseBtn = useCallback(() => {
      setAddImageModal(prev => ({ ...prev, active: false, modal: false }));
      setShowRejectReason(true);
    }, [setShowRejectReason, setAddImageModal]);

    const reasonDetailModalCloseBtn = useCallback(() => {
      setReasonDetailModal(prev => ({ ...prev, active: false, modal: false }));
      setShowRejectReason(true);
    }, [setReasonDetailModal, setShowRejectReason]);

    const onSetReasonDetailModal = useCallback(
      (reason, groupKey) => {
        const updatedReasonDetailModal = {
          groupKey: groupKey,
          reason: reason,
          modal: true,
          active: true,
        };
        setReasonDetailModal(updatedReasonDetailModal);
        setShowRejectReason(false);
      },
      [setShowRejectReason, setReasonDetailModal],
    );
    const handleSubmit = useCallback(() => {
      if (numOfReasonsSelected.length > 0) {
        const filteredReasons = filterReasons(rejectReasons, rejectReasonState);
        const reasons = mapReasons(filteredReasons, rejectReasonState);
        onConfirmCb(reasons, note, images);
      }
    }, [
      note,
      images,
      numOfReasonsSelected.length,
      onConfirmCb,
      rejectReasonState,
      rejectReasons,
    ]);
    return (
      <Fragment>
        {showRejectReason && (
          <Modal
            centered={true}
            size='xl'
            show={showRejectReason}
            onHide={onCloseCb}
            dialogClassName='track-trace-dialog'
          >
            <Modal.Header
              closeButton
              className='px-32'
              style={{ borderBottom: '0px' }}
            >
              <Modal.Title>
                <div>Rejection of {scanCode}</div>
                {lastScan && lastScan.uuid && (
                  <ScanHistoryDetail scanRecord={lastScan} />
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '550px' }} className='px-32 py-0'>
              <div className='row  rubik-font '>
                <span className='my-2 fs-5 pb-3 '>
                  Select Multiple Reasons (
                  {numOfReasonsSelected.length > 0
                    ? `${numOfReasonsSelected.length} Selected`
                    : `one by one`}
                  )
                </span>
                <div className='search-container'>
                  <input
                    type='text'
                    id='search_field'
                    className='searchBox'
                    placeholder='Tap here to search'
                    value={searchKeyword}
                    onChange={e => setSearchKeyword(e.target.value)}
                    style={{ width: isMobile ? '300px' : '' }}
                  />
                </div>
              </div>

              <GroupSelector
                reasons={
                  showMore
                    ? rejectReasons
                    : isMobile
                    ? rejectReasons.slice(0, 4)
                    : rejectReasons.slice(0, 6)
                }
                groupSelected={groupSelected}
                setGroupSelected={setGroupSelected}
                showMore={showMore}
                setShowMore={setShowMore}
                totalLength={rejectReasons.length}
              />
              <div style={{ minHeight: isMobile ? '' : '315px' }}>
                <ReasonList
                  filteredRejectReasons={filteredRejectReasons()}
                  rejectReasonState={rejectReasonState}
                  setRejectReasonState={setRejectReasonState}
                  onSetReasonDetailModal={onSetReasonDetailModal}
                />
              </div>
              <div>
                <div
                  className={`rubik-font  ${
                    isMobile
                      ? 'pb-3'
                      : 'pb-3 d-flex justify-content-between align-items-center'
                  }`}
                >
                  <div
                    className={`pe-2 ${
                      isMobile
                        ? 'd-flex flex-column align-items-start'
                        : 'd-flex flex-wrap pt-0 gap-4 align-items-center'
                    }`}
                  >
                    <div className='d-flex gap-4 align-items-center'>
                      <div onClick={addMore} className='add-more-btn'>
                        ADD MORE DETAILS
                      </div>
                    </div>
                    <div className={isMobile && ' py-4 '}>
                      <span
                        onClick={() => images.length > 0 && addMore()}
                        className={
                          images.length > 0
                            ? 'ps-3 text-primary cursor-pointer'
                            : 'ps-3 '
                        }
                      >
                        {images.length > 0 && images.length} Images
                      </span>
                      <span
                        onClick={() => note && addMore()}
                        className={
                          note ? 'ps-3 text-primary cursor-pointer' : 'ps-3 '
                        }
                      >
                        {note ? '1 Note' : 'Note'}
                      </span>
                    </div>
                  </div>
                  <div className={`d-flex justify-content-end`}>
                    <div className={`pe-2`}>
                      <Button
                        variant='outline-primary'
                        size='lg '
                        onClick={e => {
                          setShowRejectReason(true);
                          onCloseCb();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className='ps-2'>
                      <Button
                        size='lg'
                        variant='outline-none'
                        className={
                          numOfReasonsSelected.length > 0
                            ? 'bg-primary text-light'
                            : 'reject-btn'
                        }
                        style={{
                          cursor:
                            numOfReasonsSelected.length > 0
                              ? 'pointer'
                              : 'not-allowed',
                        }}
                        onClick={handleSubmit}
                      >
                        Reject
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {reasonDetailModal.modal && (
          <ReasonDetailModal
            reasonDetail={reasonDetailModal}
            setRejectReasonState={setRejectReasonState}
            rejectReasonState={rejectReasonState}
            scanCode={scanCode}
            isMobile={isMobile}
            showModal={reasonDetailModal.modal}
            setShowImageSelectionModal={setShowImageSelectionModal}
            localImages={localImages}
            setLocalImages={setLocalImages}
            setShowImageListModal={setShowImageListModal}
            onCloseCb={reasonDetailModalCloseBtn}
          />
        )}
        {addImageModal.modal && (
          <AddImageModal
            note={note}
            setNote={setNote}
            images={images}
            setImages={setImages}
            reasonDetail={reasonDetailModal}
            scanCode={scanCode}
            isMobile={isMobile}
            showModal={addImageModal.modal}
            onCloseCb={addImgModalCloseBtn}
            setLocalImages={setLocalImages}
            localImages={localImages}
            setShowImageSelectionModal={setShowImageSelectionModal}
            setShowImageListModal={setShowImageListModal}
          />
        )}
      </Fragment>
    );
  },
);

export const RejectReasonModal = memo(
  ({
    showModal,
    onCloseCb,
    onConfirmCb,
    rejectReasons,
    scanCode,
    lastScan,
  }) => {
    const [isMobile] = useMobile();
    const [rejectReasonState, setRejectReasonState] = useState([]);
    const [note, setNote] = useState('');
    const [showRejectReason, setShowRejectReason] = useState(true);
    const [showImageSelectionModal, setShowImageSelectionModal] =
      useState(false);
    const [showImageListModal, setShowImageListModal] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [cameraImageData, setCameraImageData] = useState('');
    const [localImages, setLocalImages] = useState([]);
    const hiddenFileInput = React.useRef(null);
    const [reasonDetailModal, setReasonDetailModal] = useState({
      modal: false,
      active: false,
    });
    const [addImageModal, setAddImageModal] = useState({
      modal: false,
      active: false,
    });
    const [cameraFacingMode, setCameraFacingMode] = useState('environment');
    const [cameraModeStyle] = useState({
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      position: 'fixed',
      zIndex: '100000',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)',
    });
    const modalController = useCallback((setModal, modal) => {
      setModal(prev => ({ ...prev, modal }));
    }, []);

    const handleUploadInputChange = useCallback(
      async event => {
        const files = event.target.files;
        const readFileData = async file => {
          return await new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = evt => {
              const baseURL = reader.result;
              resolve(baseURL);
            };
            reader.readAsDataURL(file);
          });
        };
        const newImages = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[`${i}`];
          if (
            ['jpeg', 'png', 'jpg'].filter(val => file.type.indexOf(val) >= 0)
              .length === 0
          ) {
            continue;
          }
          const imageData = await readFileData(file);
          newImages.push({
            data: imageData,
            name: file.name,
            type: file.type,
          });
        }
        if (newImages.length > 0) {
          setLocalImages(i => {
            return [...i, ...newImages];
          });
          setShowImageListModal(true);
          setShowImageSelectionModal(false);
          modalController(
            (addImageModal.active && setAddImageModal) ||
              (reasonDetailModal.active && setReasonDetailModal),
            false,
          );
        }
      },
      [addImageModal, modalController, reasonDetailModal],
    );

    const onSaveImages = useCallback(() => {
      setLocalImages(imageList => {
        return [
          ...imageList,
          {
            data: cameraImageData,
            name: 'Image ' + (imageList.length + 1),
            type: 'image/png',
          },
        ];
      });
      setCameraImageData('');
      setShowCamera(false);
      modalController(
        (addImageModal.active && setAddImageModal) ||
          (reasonDetailModal.active && setReasonDetailModal),
        true,
      );
      setShowImageListModal(true);
    }, [
      addImageModal.active,
      cameraImageData,
      modalController,
      reasonDetailModal.active,
    ]);
    useEffect(() => {
      if (showImageListModal) {
        modalController(
          (addImageModal.active && setAddImageModal) ||
            (reasonDetailModal.active && setReasonDetailModal),
          false,
        );
      }
    }, [
      showImageListModal,
      modalController,
      addImageModal.active,
      reasonDetailModal.active,
    ]);
    return (
      <Fragment>
        {showImageListModal && (
          <>
            <ImageListModal
              images={localImages}
              onImageUploadClick={() => {
                hiddenFileInput.current.click();
                setShowImageSelectionModal(false);
              }}
              onImageUpdate={imageList => {
                setLocalImages(imageList);
              }}
              onNewImage={() => {
                setShowImageSelectionModal(true);
                setShowImageListModal(false);
              }}
              onConfirmCb={() => {
                modalController(
                  (addImageModal.active && setAddImageModal) ||
                    (reasonDetailModal.active && setReasonDetailModal),
                  true,
                );
              }}
              onCloseCb={() => {
                setShowImageSelectionModal(false);
                setShowImageListModal(false);
                modalController(
                  (addImageModal.active && setAddImageModal) ||
                    (reasonDetailModal.active && setReasonDetailModal),
                  true,
                );
              }}
            />
            <input
              type='file'
              accept='image/png, image/gif, image/jpeg'
              ref={hiddenFileInput}
              onChange={handleUploadInputChange}
              style={{ display: 'none' }}
              multiple
            />
          </>
        )}
        {showCamera && cameraImageData !== '' && (
          <div
            className='p-32 track-trace-camera-image'
            style={cameraModeStyle}
          >
            <div className='w-100 text-center'>
              <img
                style={{
                  height: 'calc(100vh - 100px)',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
                src={cameraImageData}
                alt='camera'
              />
              <div className='d-flex justify-content-end track-trace-camera-image-action'>
                <div className='px-16 py-16'>
                  <div
                    className='btn btn-outline-primary'
                    onClick={() => {
                      setCameraImageData('');
                    }}
                  >
                    Delete
                  </div>
                </div>
                <div className='px-16 py-16'>
                  <div
                    className='btn btn-primary'
                    onClick={() => {
                      onSaveImages();
                    }}
                  >
                    Accept
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showCamera && cameraImageData === '' && (
          <div className='d-flex' style={cameraModeStyle}>
            <div className='m-auto'>
              <Camera
                style={{ margin: 'auto' }}
                onTakePhoto={dataUri => {
                  setCameraImageData(dataUri);
                }}
                idealFacingMode={cameraFacingMode}
                isFullscreen={true}
              />
            </div>
            <div
              className='cursor-pointer'
              style={{
                position: 'absolute',
                right: '16px',
                top: '16px',
                padding: '8px',
                backgroundColor: 'rgba(0, 0, 0,0.2)',
                borderRadius: '32px',
              }}
            >
              <img
                src={CloseIcon}
                width='32px'
                alt='Close'
                onClick={() => {
                  setShowCamera(false);
                  setShowImageListModal(true);
                  setShowImageSelectionModal(false);
                }}
              />
            </div>
            <div
              className='cursor-pointer'
              style={{
                position: 'absolute',
                right: '48px',
                bottom: '48px',
                backgroundColor: 'rgba(255, 255, 255,0.3)',
              }}
            >
              <RefreshIcon
                onClick={() => {
                  setCameraFacingMode(val =>
                    val === 'environment' ? 'user' : 'environment',
                  );
                }}
              />
            </div>
          </div>
        )}
        {showImageSelectionModal && (
          <div>
            <Modal
              centered={true}
              size={'md'}
              show={showModal}
              onHide={() => {
                setShowCamera(false);
                setShowImageSelectionModal(false);
              }}
              dialogClassName='track-trace-dialog'
            >
              <Modal.Header
                className='px-32'
                style={{ borderBottom: '0px' }}
                closeButton
              ></Modal.Header>
              <Modal.Body className='px-32 py-0'>
                <div
                  className='text-center'
                  style={{ fontSize: '24px', color: '#323232' }}
                >
                  Add Image
                </div>
                <div className='text-center p-32'>
                  <div className='pb-8'>
                    <div
                      className='btn btn-link'
                      onClick={() => {
                        setShowCamera(true);
                        modalController(
                          (addImageModal.active && setAddImageModal) ||
                            (reasonDetailModal.active && setReasonDetailModal),
                          false,
                        );
                      }}
                    >
                      Open Camera
                    </div>
                  </div>
                  <div
                    className='pt-8'
                    style={{ borderTop: '1px #D8D8D8 solid' }}
                  >
                    <div
                      className='btn btn-link'
                      onClick={() => {
                        hiddenFileInput.current.click();
                      }}
                    >
                      Upload Image
                    </div>
                    <input
                      type='file'
                      accept='image/png, image/gif, image/jpeg'
                      ref={hiddenFileInput}
                      onChange={handleUploadInputChange}
                      style={{ display: 'none' }}
                      multiple
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}

        <RejectReasonListModal
          scanCode={scanCode}
          isMobile={isMobile}
          showRejectReason={showRejectReason}
          setShowRejectReason={setShowRejectReason}
          onCloseCb={onCloseCb}
          setShowImageListModal={setShowImageListModal}
          rejectReasons={rejectReasons}
          setRejectReasonState={setRejectReasonState}
          rejectReasonState={rejectReasonState}
          note={note}
          setNote={setNote}
          onConfirmCb={onConfirmCb}
          lastScan={lastScan}
          setShowImageSelectionModal={setShowImageSelectionModal}
          localImages={localImages}
          setLocalImages={setLocalImages}
          addImageModal={addImageModal}
          setAddImageModal={setAddImageModal}
          reasonDetailModal={reasonDetailModal}
          setReasonDetailModal={setReasonDetailModal}
        />
      </Fragment>
    );
  },
);

RejectReasonModal.propTypes = {
  scanCode: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  onCloseCb: PropTypes.func.isRequired,
  onConfirmCb: PropTypes.func.isRequired,
};

export default RejectReasonModal;
