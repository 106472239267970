import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  NaadiIcon,
  PackingIcon,
  ReportIcon,
  ShipmentIcon,
  TrackTraceIcon,
  OrdersIcon,
  OrgIcon,
  SideMenuCollapseIcon,
  MenuChevronUpIcon,
  MenuChevronDownIcon,
  ProfileIcon,
  CutSmartIcon,
  CuttingGuideIcon,
  DigitalPodIcon,
  ItemIcon,
} from '../../assets/icons/nav-icons';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSideMenu, closeSideMenu } from '../../store/app/nav/actions';
import { closeScreen } from '../../store/app/screenoverlay/actions';
import useMobile from '../../hooks/useMobile';
import { isEqual, cloneDeep } from 'lodash';
import { HomeIcon } from '../../assets/icons/nav-icons';
import { logout } from '../../helpers/org/orgHelper';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory, useLocation } from 'react-router-dom';
const _menuLinks = [
  {
    nav: 'Home',
    link: '/app/dashboard',
    match: ['^/app/dashboard', '^#'],
    text: 'Home',
    //module: 'Home',
    icon: selected => {
      return <HomeIcon fill={selected ? '#17FF00' : null} />;
    },
    selected: true,
    children: [
      {
        nav: 'dashboard',
        link: '/app/dashboard',
        match: ['^/app/dashboard', '^/$'],
        text: 'Dashboard',
        selected: false,
      },
    ],
  },
  {
    nav: 'organisations',
    link: '/app/org/list',
    match: ['^/app/org'],
    text: 'Organizations',
    module: 'orgs',
    actions: ['maintain', 'create', 'edit'],
    icon: selected => {
      return <OrgIcon fill={selected ? '#17FF00' : null} />;
    },
    children: [],
    selected: false,
  },
  {
    nav: 'orders-main',
    link: '/app/orders/list',
    match: ['^/app/orders'],
    text: 'Orders',
    icon: selected => {
      return <OrdersIcon fill={selected ? '#17FF00' : null} />;
    },
    selected: false,
    children: [
      {
        nav: 'orders',
        link: '/app/orders/list',
        match: ['^/app/orders/list'],
        text: 'List Orders',
        selected: false,
      },
      {
        nav: 'bulkupload',
        link: '/app/orders/bulkupload',
        match: ['^/app/orders/bulkupload'],
        text: 'Bulk Upload',
        selected: false,
      },
      {
        nav: 'orderprogress',
        link: '/app/orders/progress',
        match: ['^/app/orders/progress'],
        text: 'Progress',
        selected: false,
      },
      {
        nav: 'pssummary',
        link: '/app/orders/pssummary',
        match: ['^/app/orders/pssummary'],
        text: 'Production Summary',
        selected: false,
      },
    ],
  },
  {
    nav: 'cutsmart',
    link: '/app/cutsmart',
    match: ['^/app/cutsmart'],
    module: 'cutsmart',
    actions: ['create', 'edit', 'view', 'view'],
    text: 'Cut Smart',
    icon: selected => {
      return (
        <div
          style={{
            paddingTop: '8px',
            paddingLeft: '2px',
            paddingRight: '8px',
            test: '1',
          }}
        >
          <CutSmartIcon fill={selected ? '#17FF00' : null} />
        </div>
      );
    },
    children: [],
    selected: false,
  },
  {
    nav: 'cutguide',
    link: '/app/cutguide',
    match: ['^/app/cutguide'],
    module: 'cutsmart',
    text: 'Cut Guide',
    actions: ['cutguide', 'create', 'edit', 'view', 'view'],
    icon: selected => {
      return (
        <div
          style={{
            paddingTop: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            test: '1',
          }}
        >
          <CuttingGuideIcon fill={selected ? '#17FF00' : null} />
        </div>
      );
    },
    children: [],
    selected: false,
  },

  {
    nav: 'packing',
    link: '/app/packing',
    match: ['^/app/packing'],
    text: 'Packing',
    icon: selected => {
      return <PackingIcon fill={selected ? '#17FF00' : null} />;
    },
    module: 'packing',
    children: [],
    selected: false,
  },
  {
    nav: 'shipment',
    link: '/app/shipment',
    match: ['^/app/shipment'],
    text: 'Shipment',
    icon: selected => {
      return <ShipmentIcon fill={selected ? '#17FF00' : null} />;
    },
    children: [],
    module: 'shipments',
    selected: false,
  },
  {
    nav: 'tracktrace',
    link: '/app/tracktrace',
    match: ['^/app/tracktrace'],
    module: 'trackntrace',
    text: 'Track N Trace',
    icon: selected => {
      return (
        <div
          style={{
            paddingTop: '8px',
            paddingLeft: '2px',
            paddingRight: '8px',
          }}
        >
          <TrackTraceIcon fill={selected ? '#17FF00' : null} />
        </div>
      );
    },
    children: [],
    selected: false,
  },
  {
    nav: 'digitalpod',
    link: '/app/digitalpod',
    match: ['^/app/digitalpod'],
    module: 'digitalpod',
    text: 'Digital POD',
    icon: selected => {
      return (
        <div
          style={{
            paddingTop: '8px',
            paddingLeft: '2px',
            paddingRight: '8px',
            test: '1',
          }}
        >
          <DigitalPodIcon fill={selected ? '#17FF00' : null} />
        </div>
      );
    },
    children: [],
    selected: false,
  },
  {
    nav: 'itemslist',
    link: '/app/items/list',
    match: ['^/app/items'],
    module: 'items',
    text: 'Items',
    icon: selected => {
      return (
        <div
          style={{
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          <ItemIcon fill={selected ? '#17FF00' : null} />
        </div>
      );
    },
    children: [],
    selected: false,
  },
  {
    nav: 'report',
    link: '/app/report',
    match: ['^/app/report'],
    text: 'Report',
    icon: selected => {
      return <ReportIcon fill={selected ? '#17FF00' : null} />;
    },
    children: [],
    selected: false,
  },
  {
    nav: 'profile',
    link: null,
    match: [],
    text: 'My Profile',
    icon: selected => {
      return (
        <div
          style={{
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          <ProfileIcon fill={selected ? '#17FF00' : null} />
        </div>
      );
    },
    children: [
      {
        nav: 'logout',
        link: null,
        linkCb: (dispatch, keycloak) => {
          if (dispatch) {
            dispatch(logout());
          }
          if (keycloak) {
            keycloak.logout();
          }
        },
        match: [],
        text: 'Logout',
        selected: false,
      },
      {
        nav: 'groups',
        link: '/app/groups/list',
        match: ['^/app/groups'],
        text: 'Groups',
        module: 'profiles',
        selected: false,
      },
      {
        nav: 'profiles',
        link: '/app/profiles/list',
        match: ['^/app/profiles'],
        text: 'Profiles',
        module: 'profiles',
        selected: false,
      },
      {
        nav: 'users',
        link: '/app/users/list',
        match: ['^/app/users'],
        text: 'Users',
        module: 'users',
        selected: false,
      },
      {
        nav: 'labeltemplates',
        link: '/app/labeltemplates/templatecategory',
        match: ['^/app/labeltemplates'],
        module: 'profiles',
        text: 'Templates',
        selected: false,
      },
      {
        nav: 'rejection',
        link: '/app/rejection/list',
        match: ['^/app/rejection'],
        module: 'profiles',
        text: 'Rejection Reasons',
        selected: false,
      },
      {
        nav: 'uploadformat',
        link: '/app/uploadformat/uploadformat',
        match: ['^/app/uploadformat'],
        module: 'profiles',
        text: 'Upload Format',
        selected: false,
      },
      {
        nav: 'downloadformat',
        link: '/app/downloadformat/downloadformat',
        match: ['^/app/downloadformat'],
        module: 'profiles',
        text: 'Download Format',
        selected: false,
      },
      {
        nav: 'bot',
        link: '/app/bot/bots',
        match: ['^/app/bot'],
        module: 'profiles',
        text: 'BOT',
        selected: false,
      },
      {
        nav: 'operations',
        link: '/app/operations/operation',
        match: ['^/app/operations'],
        module: 'profiles',
        text: 'Operations',
        selected: false,
      },
      {
        nav: 'stations',
        link: '/app/stations/station',
        match: ['^/app/stations'],
        module: 'profiles',
        text: 'Stations',
        selected: false,
      },
      {
        nav: 'workstations',
        link: '/app/workstations/workstation',
        match: ['^/app/workstation'],
        module: 'profiles',
        text: 'Work Station',
        selected: false,
      },
      {
        nav: 'workflowrules',
        link: '/app/workflowrules/workflow',
        match: ['^/app/workflowrules'],
        module: 'profiles',
        text: 'Work Flow Rules',
        selected: false,
      },
    ],
    selected: false,
    toggled: false,
  },
];
const SubLink = memo(({ menu, parent, index, onSubmenuClickCb }) => {
  const subMenuClick = useCallback(
    e => {
      onSubmenuClickCb(menu, parent);
      e.stopPropagation();
    },
    [onSubmenuClickCb, menu, parent],
  );
  return (
    <div
      key={`${menu.nav}-${index}`}
      onClick={subMenuClick}
      className={`side-menu-submenu-item ${menu.selected ? 'active' : ''}`}
    >
      <div>{menu.text}</div>
    </div>
  );
});
const checkMenuSelected = menu => {
  if (menu.children && menu.children.length > 0) {
    return menu.children.filter(val => val.selected === true).length > 0;
  }
  return false;
};
const SideMenuLink = memo(
  ({
    menu,
    index,
    submenuState,
    onToggleSubmenu,
    onClickCb,
    onSubmenuClickCb,
  }) => {
    const isChildSelected = checkMenuSelected(menu);
    return (
      <li
        key={`${menu.nav}-${index}`}
        className={`nav-item-sidebar nav-item${menu.selected ? ' active' : ''}`}
      >
        {
          /* eslint-disable */
          <div
            onClick={() => onClickCb(menu)}
            className='nav-link nav-item-sidebar-link'
          >
            <div>{menu.icon(menu.selected || isChildSelected)}</div>
            <div
              onClick={e => {
                if (menu.children && menu.children.length > 0) {
                  if (!menu.link) {
                    onClickCb(menu);
                    e.stopPropagation();
                  } else {
                    onToggleSubmenu(menu);
                  }
                }
              }}
              className='text width-100-percent position-relative'
              style={{ whiteSpace: 'nowrap' }}
            >
              <div>{menu.text}</div>
              {menu.children && menu.children.length > 0 && (
                <div
                  key={`submenu-${menu.nav}`}
                  className={'nav-link-submenu-wrp'}
                >
                  <div className={`nav-link-submenu`}>
                    {submenuState[`${menu.nav}`] &&
                      menu.selected &&
                      menu.toggled && (
                        <MenuChevronUpIcon
                          fill={isChildSelected ? '#17FF00' : null}
                        />
                      )}
                    {(!submenuState[`${menu.nav}`] ||
                      !(menu.selected && menu.toggled)) && (
                      <MenuChevronDownIcon fill={null} />
                    )}
                  </div>
                  {submenuState[`${menu.nav}`] &&
                    menu.selected &&
                    menu.toggled &&
                    menu.children.map((subMenu, subIndex) => {
                      return (
                        <SubLink
                          key={subMenu.nav}
                          menu={subMenu}
                          parent={menu}
                          index={subIndex}
                          onSubmenuClickCb={onSubmenuClickCb}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          /* eslint-enable */
        }
      </li>
    );
  },
);
const SideMenu = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  //const navigate = useNavigate();
  //eslint-disable-next-line
  const [isMobile, isTab, isTl, isLg] = useMobile();
  //const [isXl] = useXl();
  const user = useSelector(o => o.user);
  //const collapsed = useSelector(state => state.appNav.sideMenuToggled);
  const [submenuState, setSubMenuState] = useState({});
  const [userNameAndImage, setUserNameAndImage] = useState({});
  /*const toggleTime = useRef({
    collapsed: collapsed,
    time: 0,
  });
  useEffect(() => {
    toggleTime.current.time = new Date().getTime();
    toggleTime.current.collapsed = collapsed;
    setTimeout(() => {
      if (isMobile || isTab || isTl) {
        return;
      }
      if (!isXl && toggleTime.current.collapsed) {
        const diff = new Date().getTime() - toggleTime.current.time;
        if (diff > 18000) {
          dispatch(closeSideMenu());
        }
      } else if (isXl && !toggleTime.current.collapsed) {
        const diff = new Date().getTime() - toggleTime.current.time;
        if (diff > 18000) {
          dispatch(openSideMenu());
        }
      }
    }, 20000);
  }, [dispatch, collapsed, isMobile, isXl, isTl, isTab]);*/
  useEffect(() => {
    let name = '';
    if (user && user.first_name) {
      name = user.first_name.substring(0, 1);
    }
    let loginId = '';
    if (user && user.org && user.org_users) {
      const userOrg = user.org_users[`${user.org.uuid}`];
      if (userOrg && userOrg.login_ids && userOrg.login_ids.length > 0) {
        loginId = userOrg.login_ids[0];
      }
    }
    setUserNameAndImage({
      name: name,
      image: null,
      login_id: loginId,
    });
  }, [setUserNameAndImage, user]);
  const filterMenuAccess = useCallback(() => {
    const filterFn = val => {
      let returnVal = true;
      if (!val.module) {
        returnVal = true;
      } else if (
        !user ||
        !user.org ||
        !user.branch ||
        !user.branch.branch_id ||
        !user.access ||
        !user.access[`${user.org.uuid}`]
      ) {
        returnVal = false;
      } else {
        const orgAccess = user.access[`${user.org.uuid}`];
        const branchAccess = orgAccess.find(
          val => val.branch_id === user.branch.branch_id,
        );
        if (!branchAccess) {
          returnVal = false;
        }
        if (
          !branchAccess.module_access ||
          !branchAccess.module_access[`${val.module}`]
        ) {
          returnVal = false;
        }
        if (returnVal && val.actions) {
          const moduleAccess =
            branchAccess.module_access[`${val.module}`] || {};
          const actions = val.actions.filter(a => moduleAccess[`${a}`]);
          returnVal = actions.length > 0;
        }
      }

      return returnVal;
    };
    const filteredLinks = _menuLinks.filter(val => {
      return filterFn(val);
    });
    return filteredLinks.map(val => {
      const newVal = cloneDeep(val);
      if (newVal.children && newVal.children.length > 0) {
        newVal.children = newVal.children.filter(filterFn);
      }

      return newVal;
    });
  }, [user]);
  const [menuLinks, setMenuLinks] = useState([]);
  const selectedMenuLinks = useRef(menuLinks);

  const updateMenuSelection = useCallback(
    menu => {
      if (menu.children) {
        const childSelected = menu.children
          .map(val => {
            updateMenuSelection(val);
            return val;
          })
          .find(val => val.selected);
        if (childSelected && submenuState[`${menu.nav}`] !== true) {
          const _submenuState = cloneDeep(submenuState);
          _submenuState[`${menu.nav}`] = true;
          setSubMenuState(_submenuState);
          //menu.show_submenu = true;
        }
      }
      let menuMultiMatch = false;
      menu.match.map(val => {
        if (menuMultiMatch === true) return true;
        const matchFound = location.pathname.match(val);
        if (matchFound) {
          menuMultiMatch = true;
        }
        return true;
      });
      if (menuMultiMatch !== menu.selected) {
        menu.selected = menuMultiMatch;
      }
      return menu;
    },
    [submenuState, setSubMenuState, location.pathname],
  );
  useEffect(() => {
    const __menuLinks = cloneDeep(filterMenuAccess()).map(updateMenuSelection);
    if (!isEqual(__menuLinks, selectedMenuLinks.current)) {
      selectedMenuLinks.current.forEach(mL => {
        __menuLinks.forEach(_mL => {
          if (
            _mL.nav === mL.nav &&
            _mL.selected === true &&
            _mL.children &&
            _mL.children.length > 0 &&
            _mL.toggled === undefined
          ) {
            _mL.toggled = true;
          }
        });
      });
      setMenuLinks(__menuLinks);
      selectedMenuLinks.current = __menuLinks;
    }
  }, [filterMenuAccess, setMenuLinks, updateMenuSelection]);

  const onSubmenuClickCb = useCallback(
    (menu, parent) => {
      if (menu.link) {
        const link = menu.link;
        history.push(link);
        //navigate(link, { replace: true });
        dispatch(closeScreen());
        if (isMobile) {
          dispatch(closeSideMenu());
        }
      } else if (menu.linkCb) {
        menu.linkCb(dispatch, keycloak);
      }
    },
    [history, dispatch, isMobile, keycloak],
  );
  const onToggleSubmenu = useCallback(
    menu => {
      if (!menu.children || menu.children.length === 0) {
        return;
      }
      const _submenuState = cloneDeep(submenuState);
      _submenuState[`${menu.nav}`] = !_submenuState[`${menu.nav}`];
      if (checkMenuSelected(menu)) {
        _submenuState[`${menu.nav}`] = true;
      }
      if (submenuState[`${menu.nav}`] !== _submenuState[`${menu.nav}`]) {
        setSubMenuState(_submenuState);
      }
      setMenuLinks(links => {
        const _links = links.map((val, index) => {
          const _val = cloneDeep(val);
          if (_val.nav === menu.nav) {
            _val.toggled = _val.toggled === undefined ? true : !_val.toggled;
          }
          return _val;
        });
        selectedMenuLinks.current = _links;
        return _links;
      });
    },
    [submenuState, setSubMenuState, setMenuLinks],
  );
  /* eslint-disable sonarjs/cognitive-complexity */
  const onClickCb = useCallback(
    menu => {
      if (menu.children && menu.children.length > 0) {
        /* eslint-enable  sonarjs/no-duplicated-branches */

        const subMenuStateVal = submenuState[`${menu.nav}`];
        if (!subMenuStateVal) {
          onToggleSubmenu(menu);
        }
      }
      if (!menu.link) {
        setMenuLinks(links => {
          const newLinks = cloneDeep(links);
          return newLinks.map(val => {
            if (val.nav === menu.nav) {
              val.selected = val.selected === undefined ? true : !val.selected;
              val.toggled = val.toggled === undefined ? true : !val.toggled;
            }
            return val;
          });
        });
        return;
      }
      if (!menu.children || menu.children.length === 0 || !menu.selected) {
        const link = menu.link;
        history.push(link);
        //navigate(link, { replace: true });
        dispatch(closeScreen());
        if (isMobile) {
          // dispatch(closeSideMenu());
        }
      }
    },
    [history, dispatch, isMobile, onToggleSubmenu, submenuState],
  );
  /* eslint-enable sonarjs/cognitive-complexity */

  const handleMenuToggle = useCallback(() => {
    dispatch(toggleSideMenu());
  }, [dispatch]);

  return (
    <div className='side-menu py-3 d-print-none'>
      <div className='side-menu-logo-full'>
        <div>
          <NaadiIcon is_text_white onClick={handleMenuToggle} />
        </div>
      </div>
      <div className='side-menu-pull'>
        <div className='display-flex'>
          <div className='cursor-pointer' onClick={handleMenuToggle}>
            <SideMenuCollapseIcon fill={'#fff'} />
          </div>
        </div>
      </div>
      <ul className='nav  side-bar-menu-scroll mb-0 ps-0 '>
        {menuLinks.map((menu, index) => {
          return (
            <SideMenuLink
              key={'menunav-' + menu.nav}
              menu={menu}
              index={index}
              submenuState={submenuState}
              onToggleSubmenu={onToggleSubmenu}
              onClickCb={onClickCb}
              onSubmenuClickCb={onSubmenuClickCb}
            />
          );
        })}
      </ul>
      <div className='side-bar-profile'>
        <div className='profile-wrp'>{userNameAndImage.name}</div>
        <div className='profile-user'>{userNameAndImage.login_id}</div>
      </div>
    </div>
  );
};

export default SideMenu;
