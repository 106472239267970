import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useMobile from '../../../hooks/useMobile';
import { SelectContext } from '../../../components/common/select-context';
import { ProcessedStatus, Progress } from '../status/processed-status';

import { getScanStateClass } from '../../../helpers/tracktrace/orderItemHelper';
import { LocationSelectText } from '../../../components/tracktrace/location-select';
import TrackTraceRejectToggle from '../../../components/tracktrace/reject-toggle';
import OfflineScanIcon from '../../../assets/icons/offline-scan-icon.svg';
import { MODES } from '../../../helpers/digitalpod/digitalPodHelper';
import { CutGuideWoCardStat } from '../../cutguide/common';
// eslint-disable-next-line sonarjs/cognitive-complexity
const SubHeader = ({
  isClosed,
  pipelineMode,
  onCtxTypeSelect,
  onCtxValueSelect,
  rejectmode,
  onRejectModeToggle,
  scanState,
  currentStation,
  ctx,
  onShowOfflineScans,
  location,
  history,
  dPodResult,
  refreshResult,
  mode,
  shipment,
}) => {
  const [isMobile, isTab] = useMobile();

  const [ctxTypes] = useState(['SHIPMENT_COLLABORATOR']);
  const [scanStateClass, setScanStateClass] = useState('bg-white');

  const { busy } = useSelector(state => state.tracktracestateless);
  useEffect(() => {
    setScanStateClass(getScanStateClass(scanState));
  }, [scanState]);

  return (
    <div
      className={`row sub-header  mx-1 pb-4 ${scanStateClass}`}
      style={{ paddingTop: '86px' }}
    >
      <div className='row'>
        {mode === MODES.SHIPMENT_LISTING && (
          <Fragment>
            <div className='col-sm-5 col-xs-12 col-lg-6 p-0 d-inline-block'>
              <div className={'tnt-wo-selector-wrp'}>
                <div
                  className='order-select ms-4 position-relative d-flex align-content-center flex-column'
                  style={{ zIndex: 9 }}
                >
                  <SelectContext
                    types={ctxTypes}
                    onCtxTypeSelect={onCtxTypeSelect}
                    onCtxValueSelect={onCtxValueSelect}
                    ctxType={ctx ? ctx.type : null}
                    ctxValue={pipelineMode ? null : ctx ? ctx.value : null}
                    showBusy={busy && busy.length > 0}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}

        {mode === MODES.SHIPMENT_LISTING && (
          <div className='col-sm-7 col-lg-6 col-xs-12 p-0 d-inline-block '>
            <div className='justify-content-end'>
              {ctx && ctx.value && ctx.value.key && (
                <div className='d-flex w-100  pb-2'>
                  <Progress
                    pipelineMode={''}
                    label={'Progress'}
                    onClick={null}
                    processed={dPodResult.stat.total_processed}
                    total={dPodResult.stat.total_shipped || 0}
                    statusActions={[
                      {
                        key: 'refresh',
                        label: 'Refresh Items',
                        onClick: refreshResult,
                      },
                    ]}
                  />
                </div>
              )}
              <div className={`d-flex justify-content-end`}>
                <Fragment>
                  <div className='track-trace-status-box-wrapper'>
                    <ProcessedStatus
                      statusType={'SHIPMENT_PENDING_COUNT'}
                      ctx={ctx}
                      pipelineMode={''}
                      labelType={'INFO'}
                      dPodResult={dPodResult}
                      current_station={currentStation}
                    />
                  </div>
                  <div className='track-trace-status-box-wrapper'>
                    <ProcessedStatus
                      statusType={'SHIPMENT_ITEM_COUNT'}
                      ctx={ctx}
                      pipelineMode={''}
                      labelType={'INFO'}
                      dPodResult={dPodResult}
                      current_station={currentStation}
                    />
                  </div>
                  <div className='track-trace-status-box-wrapper'>
                    <ProcessedStatus
                      statusType={'CTX_TOTAL'}
                      ctx={ctx}
                      pipelineMode={''}
                      labelType={'INFO'}
                      dPodResult={dPodResult}
                      current_station={currentStation}
                    />
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        )}
        {!isTab &&
          mode === MODES.SHIPMENT_ITEM_LISTNG &&
          shipment &&
          shipment.shipment && (
            <Fragment>
              <div className='rubik-font pt-3 pb-1 d-flex w-100 justify-content-start'>
                <CutGuideWoCardStat
                  statCount={shipment.stat.box_shipped_processed}
                  totalCount={shipment.stat.box_total}
                  label={'BOXES'}
                />
                <CutGuideWoCardStat
                  statCount={shipment.stat.item_shipped_processed}
                  totalCount={shipment.stat.item_total}
                  label={'ITEMS'}
                />
              </div>
            </Fragment>
          )}
        {isTab && currentStation && (
          <div className='row pt-2 justify-content-end'>
            <div className='d-flex col-sm-8 justify-content-end align-items-center w-100'>
              {mode === MODES.SHIPMENT_ITEM_LISTNG &&
                shipment &&
                shipment.shipment && (
                  <Fragment>
                    <div className='rubik-font pt-3 pb-1 d-flex w-100 justify-content-start'>
                      <CutGuideWoCardStat
                        statCount={shipment.stat.box_shipped_processed}
                        totalCount={shipment.stat.box_total}
                        label={'BOXES'}
                      />
                      <CutGuideWoCardStat
                        statCount={shipment.stat.item_shipped_processed}
                        totalCount={shipment.stat.item_total}
                        label={'ITEMS'}
                      />
                    </div>
                  </Fragment>
                )}
              {currentStation.allow_offline_scan && (
                <div className='d-flex'>
                  <div className='pt-2'>
                    <div className=' track-trace-location-txt'>
                      <div
                        onClick={onShowOfflineScans}
                        className={
                          'btn-label text-primary ' +
                          ' text-button-padded cursor-pointer  pe-0 ps-2'
                        }
                      >
                        <img
                          src={OfflineScanIcon}
                          alt='Scan here to pack items'
                          className='cursor-pointer pe-2'
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '90%',
                      width: '1px',
                      margin: '8px',
                      backgroundColor: '#000',
                    }}
                  ></div>
                </div>
              )}
              <div className='ps-4 py-2'>
                <div className='d-flex align-items-center'>
                  <div>Location:</div>
                  <div className='ps-1 track-trace-location-txt'>
                    <LocationSelectText />
                  </div>
                </div>
              </div>
            </div>

            {isMobile && (
              <div className='col-sm-4 d-flex w-100 justify-content-end align-items-center'>
                <div className='pe-2'>Reject Mode</div>
                <TrackTraceRejectToggle
                  rejectmode={rejectmode}
                  onRejectModeToggle={onRejectModeToggle}
                  hideLable={true}
                />{' '}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
SubHeader.propTypes = {};
export default SubHeader;
