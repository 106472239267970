import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { React, Fragment } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  faChevronLeft,
  faCircleXmark,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { TrackTraceImageRemoveIcon } from '../../assets/icons/icon-tracktrace';
import { useDispatch, useSelector } from 'react-redux';
import {
  setComment,
  setCurrentState,
} from '../../store/tracktrace/rejection-reason';
export const ReasonDetailModal = ({
  reasonDetail,
  rejectReasonState,
  setRejectReasonState,
  scanCode,
  isMobile,
  showModal,
  setShowImageSelectionModal,
  onCloseCb,
  localImages,
  setLocalImages,
  setShowImageListModal,
}) => {
  const dispatch = useDispatch();
  const groupKey = reasonDetail.groupKey;
  const [resOpSearch, setResOpSearch] = useState('');
  const [reworkOpSearch, setReWorkSearch] = useState('');
  const currentState = useSelector(state => state.rejectionreason.currentState);
  const [mandatoryErr, setMandatoryErr] = useState('');
  useEffect(() => {
    const reason = reasonDetail.reason;
    const blameOperationMap = {};
    (reason.blame_operations || []).forEach((bo, i) => {
      if (i === 0) {
        blameOperationMap[`${bo}`] = true;
      } else {
        blameOperationMap[`${bo}`] = false;
      }
    });
    const reworkOperationMap = {};
    (reason.rework_operations || []).forEach((ro, i) => {
      if (i === 0) {
        reworkOperationMap[`${ro}`] = true;
      } else {
        reworkOperationMap[`${ro}`] = false;
      }
    });

    const extraFieldsMap = {};
    const image_reqd = reason.image_reqd;
    const cs = rejectReasonState.find(item => item.groupKey === groupKey);

    const newCurrentState = cs || {
      groupKey,
      checked: true,
      blame_operation_map: blameOperationMap,
      blame_operations: reason.blame_operations || [],
      rework_operation_map: reworkOperationMap,
      rework_operations: reason.rework_operations || [],
      extra_field_value_map: extraFieldsMap,
      extra_fields: reason.extra_fields || [],
      images: [],
      image_reqd,
    };
    dispatch(setCurrentState([newCurrentState]));
  }, [rejectReasonState, reasonDetail, groupKey, dispatch]);
  useEffect(() => {
    if (groupKey && localImages.length === 0) {
      setLocalImages(currentState[0]?.images || []);
    }
  }, [groupKey, currentState, localImages, setLocalImages]);

  useEffect(() => {
    if (groupKey && localImages.length > 0) {
      const currentGroupState = currentState.find(
        cs => cs.groupKey === groupKey,
      );
      if (currentGroupState && currentGroupState.images !== localImages) {
        const updatedState = currentState.map(cs =>
          cs.groupKey === groupKey ? { ...cs, images: localImages } : cs,
        );
        dispatch(setCurrentState(updatedState));
      }
    }
  }, [localImages, dispatch, groupKey, currentState]);

  const currentKey =
    currentState && currentState.find(cs => cs.groupKey === groupKey);

  const validateFields = useCallback(() => {
    let isValid = true;

    if (!currentState || !currentState.length) {
      isValid = false;
      return;
    }

    if (currentState[0]?.image_reqd && !currentState[0]?.images.length > 0) {
      isValid = false;
    }

    if (currentState[0]?.extra_fields) {
      for (const ef of currentState[0]?.extra_fields || []) {
        const fieldValue = currentState[0]?.extra_field_value_map[ef.field];
        if (ef.mandatory && !fieldValue) {
          isValid = false;
        }
      }
      return isValid;
    }
  }, [currentState]);
  const handleRemoveImg = useCallback(
    (e, i) => {
      e.stopPropagation();

      const filteredImgs = localImages.filter((_, index) => index !== i);

      const updatedState = currentState.map(cs =>
        cs.groupKey === groupKey ? { ...cs, images: filteredImgs } : cs,
      );

      dispatch(setCurrentState(updatedState));

      setLocalImages(filteredImgs);
    },
    [dispatch, groupKey, localImages, setLocalImages, currentState],
  );

  const handleCheckboxChange = (operationType, operation) => {
    const updatedState = currentState.map(cs => {
      if (cs.groupKey === groupKey) {
        const updatedOperationMap = {
          ...cs[`${operationType}_operation_map`],
        };
        updatedOperationMap[operation.toUpperCase()] =
          !updatedOperationMap[operation.toUpperCase()];

        return {
          ...cs,
          [`${operationType}_operation_map`]: updatedOperationMap,
        };
      }
      return cs;
    });

    dispatch(setCurrentState(updatedState));
  };

  const onClose = useCallback(() => {
    onCloseCb();
    setLocalImages([]);
  }, [onCloseCb, setLocalImages]);
  const handleSave = useCallback(() => {
    if (!validateFields()) {
      setMandatoryErr('err');
      return;
    }
    setRejectReasonState(prevState => {
      const updatedState = prevState?.filter(cs => cs.groupKey !== groupKey);
      return [...updatedState, ...currentState];
    });
    onClose();
  }, [currentState, groupKey, onClose, setRejectReasonState, validateFields]);

  return (
    <Fragment>
      <Modal
        centered={true}
        size={'xl'}
        show={showModal}
        onHide={onClose}
        dialogClassName='track-trace-dialog'
      >
        <Modal.Header className='py-32 px-32' style={{ borderBottom: '0px' }}>
          <Modal.Title className='row ps-2'>
            <div className='rubik-font font-weight addImageModal-title-text'>
              <FontAwesomeIcon
                onClick={onClose}
                icon={faChevronLeft}
                style={{ color: '#323232CC', paddingRight: '16px' }}
                className='cursor-pointer'
              />
              Rejection of {scanCode}
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='container p-32 pt-0'>
          <div className='pb-4 border-bottom-gray'>
            <div className='col-lg-12 rubik-font bg-light rounded'>
              <div className='p-3'>
                <span>Rejection Reason</span>
                <div className='fs-3 py-3'>{reasonDetail.reason.reason}</div>
              </div>
            </div>
          </div>

          {currentState[0]?.blame_operations.length > 0 && (
            <div className='col-lg-12 rubik-font border-bottom-gray'>
              <div className='p-3 ps-0'>
                <span>Responsible Operations</span>
                <div className='search-container p-3 ps-0'>
                  <input
                    type='text'
                    id='search_field'
                    className='searchBox'
                    placeholder='Tap here to search'
                    value={resOpSearch}
                    onChange={e => setResOpSearch(e.target.value)}
                    style={{ width: isMobile ? '300px' : '' }}
                  />
                </div>

                <div className='py-3 d-flex flex-wrap'>
                  {currentState[0]?.blame_operations
                    .filter(v =>
                      v.toLowerCase().includes(resOpSearch.toLowerCase()),
                    )
                    .map(blameOp => {
                      const key = blameOp.toUpperCase();
                      const currentBlameOp =
                        currentKey?.blame_operation_map[`${key}`];
                      return (
                        <div
                          className='d-flex col-lg-4 py-2 pe-5'
                          key={blameOp}
                        >
                          <div>
                            <input
                              type='checkbox'
                              className='main-box-checkbox cursor-pointer'
                              onChange={() =>
                                handleCheckboxChange('blame', blameOp)
                              }
                              checked={currentBlameOp || false}
                            />
                          </div>
                          <div className='ps-2'>{blameOp}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}

          {!currentState[0]?.rework_operations.length > 0 && (
            <div className='col-lg-12 rubik-font border-bottom-gray'>
              <div className='p-3 ps-0'>
                <span>Rework Operations</span>
                <div className='search-container p-3 ps-0'>
                  <input
                    type='text'
                    id='search_field'
                    className='searchBox'
                    placeholder='Tap here to search'
                    value={reworkOpSearch}
                    onChange={e => setReWorkSearch(e.target.value)}
                    style={{ width: isMobile ? '300px' : '' }}
                  />
                </div>
                <div className='py-3 d-flex flex-wrap'>
                  {currentState[0]?.rework_operations
                    .filter(v =>
                      v.toLowerCase().includes(reworkOpSearch.toLowerCase()),
                    )
                    .map(reworkOp => {
                      const key = reworkOp.toUpperCase();
                      const currentReworkOp =
                        currentKey?.rework_operation_map[`${key}`];
                      return (
                        <div
                          className='d-flex col-lg-4 py-3 pe-5'
                          key={reworkOp}
                        >
                          <div>
                            <input
                              type='checkbox'
                              className='main-box-checkbox cursor-pointer'
                              onChange={() =>
                                handleCheckboxChange('rework', reworkOp)
                              }
                              checked={currentReworkOp || false}
                            />
                          </div>
                          <div className='ps-2'>{reworkOp}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}

          {currentState[0]?.extra_fields.length > 0 && (
            <div className='rubik-font border-bottom-gray'>
              <div className='p-3 ps-0'>
                <span>Additional Details</span>
                {currentState[0]?.extra_fields.map(ex => {
                  return (
                    <div key={ex.display} className='pt-3'>
                      <div className='col-lg-4 py-2 px-3 rubik-font bg-light additional-input'>
                        <span style={{ fontSize: '10px' }}>{ex.display}</span>
                        <div className='d-flex justify-content-between'>
                          <input
                            type={ex.type}
                            className='form-control border-0 input-custom'
                            id='numOfOccurrence'
                            placeholder='Enter Value Here'
                            value={
                              currentKey?.extra_field_value_map?.[ex.field] ||
                              ''
                            }
                            onChange={e => {
                              const { value } = e.target;

                              const updatedState = currentState.map(cs =>
                                cs.groupKey === groupKey
                                  ? {
                                      ...cs,
                                      extra_field_value_map: {
                                        ...cs.extra_field_value_map,
                                        [ex.field]: value,
                                      },
                                    }
                                  : cs,
                              );

                              dispatch(setCurrentState(updatedState));
                            }}
                          />
                        </div>
                      </div>
                      {mandatoryErr &&
                        !currentState[0]?.extra_field_value_map[ex.field] && (
                          <span className='input-label text-danger mt-2'>
                            this filed is mandatory
                          </span>
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {currentState[0]?.image_reqd && (
            <div className='border-bottom-gray py-2'>
              <div className='d-flex justify-content-between align-items-center pt-4'>
                <div>Attach image</div>
                <div className='add-more-btn rounded-0'>
                  <label
                    htmlFor='upload'
                    className='font-weight rubik-font cursor-pointer'
                  >
                    <span
                      className='text-primary rubik-font'
                      onClick={() => setShowImageSelectionModal(true)}
                    >
                      Upload image
                    </span>
                    <FontAwesomeIcon
                      icon={faImage}
                      style={{ color: '#006EED', paddingLeft: '10px' }}
                    />
                  </label>
                </div>
              </div>
              <div className='d-flex justify-content-end pt-4'>
                {currentState[0].images.slice(0, 4).map((src, i) => {
                  let text = '';
                  let showMore = false;
                  if (i === 3 && currentState[0].images.length > 4) {
                    text = '+' + (currentState[0].images.length - 4);
                    showMore = true;
                  }
                  return (
                    <div className='ps-3' key={i}>
                      <div
                        className='position-relative justify-content-center cursor-pointer'
                        style={{
                          width: '56px',
                          height: '56px',
                          backgroundColor: '#000',
                        }}
                        onClick={() => {
                          setShowImageListModal(true);
                        }}
                      >
                        <img
                          style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                          }}
                          src={src.data || null}
                          alt={`${i}`}
                        />
                        {showMore && (
                          <div
                            style={{
                              position: 'absolute',
                              top: '18px',
                              left: '14px',
                              fontSize: '18px',
                              fontFamily: 'Rubik',
                            }}
                          >
                            {text}
                          </div>
                        )}
                        {!showMore && (
                          <div
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-12px',
                            }}
                          >
                            <TrackTraceImageRemoveIcon
                              onClick={e => handleRemoveImg(e, i)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {mandatoryErr && !currentState[0]?.images.length > 0 && (
                <span className='input-label text-danger mt-2'>
                  atleast upload one image
                </span>
              )}
            </div>
          )}

          <div className={`d-flex justify-content-end pt-4 w-100`}>
            <div className='pe-2'>
              <Button variant='outline-primary' size='lg' onClick={onClose}>
                Cancel
              </Button>
            </div>
            <div className='ps-2'>
              <Button
                variant='outline-none'
                type='submit'
                className={`${
                  validateFields()
                    ? 'ms-2 bg-primary text-light'
                    : 'reject-btn cursor-none'
                }`}
                size='lg'
                style={{ cursor: validateFields() ? 'pointer' : 'not-allowed' }}
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export const AddImageModal = ({
  note,
  setNote,
  images,
  setImages,
  scanCode,
  isMobile,
  showModal,
  onCloseCb,
  setLocalImages,
  localImages,
  setShowImageSelectionModal,
  setShowImageListModal,
}) => {
  const dispatch = useDispatch();
  const [uploadImg, setUploadImg] = useState(images || []);
  const comment = useSelector(state => state.rejectionreason.comment) || note;

  const closeModal = useCallback(() => {
    onCloseCb();
    setLocalImages([]);
    dispatch(setComment(''));
  }, [dispatch, onCloseCb, setLocalImages]);

  useEffect(() => {
    if (localImages.length > 0) {
      setUploadImg(localImages);
    }
  }, [localImages]);
  useEffect(() => {
    if (localImages.length === 0) {
      setLocalImages(images || []);
    }
  }, [images, localImages, setLocalImages]);
  const handleRemoveImg = useCallback(
    (e, i) => {
      e.stopPropagation();
      const filterdImgs = uploadImg.filter((_, index) => index !== i);
      setUploadImg(filterdImgs);
      setImages(filterdImgs);
      setLocalImages(filterdImgs);
    },
    [uploadImg, setImages, setLocalImages],
  );
  const handleSubmit = useCallback(() => {
    setNote(comment);
    setImages(uploadImg);
    closeModal();
  }, [setNote, comment, setImages, uploadImg, closeModal]);
  return (
    <Modal
      centered={true}
      size='xl'
      show={showModal}
      onHide={closeModal}
      dialogClassName='track-trace-dialog'
    >
      <Modal.Header className='py-32' style={{ borderBottom: '0px' }}>
        <Modal.Title className='row ps-3'>
          <div className='rubik-font font-weight addImageModal-title-text'>
            <FontAwesomeIcon
              onClick={closeModal}
              icon={faChevronLeft}
              style={{ color: '#323232CC', paddingRight: '16px' }}
              className='cursor-pointer'
            />
            Rejection of {scanCode}
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className='container p-32 pt-0'>
        <div className='border-bottom-gray pb-2'>
          <div className='d-flex justify-content-between align-items-center'>
            <div>Attach image</div>
            <div className='add-more-btn rounded-0'>
              <label
                htmlFor='upload'
                className='font-weight rubik-font cursor-pointer'
              >
                <span
                  className='text-primary rubik-font cursor-pointer'
                  onClick={() => setShowImageSelectionModal(true)}
                >
                  Upload image
                </span>
                <FontAwesomeIcon
                  icon={faImage}
                  style={{ color: '#006EED', paddingLeft: '10px' }}
                />
              </label>
            </div>
          </div>
          <div className='d-flex justify-content-end pt-4'>
            {uploadImg.slice(0, 4).map((src, i) => {
              let text = '';
              let showMore = false;
              if (i === 3 && uploadImg.length > 3) {
                text = '+' + (uploadImg.length - 4);
                showMore = true;
              }
              return (
                <div className='ps-3' key={i}>
                  <div
                    className='position-relative justify-content-center cursor-pointer'
                    style={{
                      width: '56px',
                      height: '56px',
                      backgroundColor: '#000',
                    }}
                    onClick={() => {
                      setShowImageListModal(true);
                    }}
                  >
                    <img
                      style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '100%',
                      }}
                      src={src.data || null}
                      alt={`${i}`}
                    />
                    {showMore && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '18px',
                          left: '14px',
                          fontSize: '18px',
                          fontFamily: 'Rubik',
                        }}
                      >
                        {text}
                      </div>
                    )}
                    {!showMore && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          right: '-12px',
                        }}
                      >
                        <TrackTraceImageRemoveIcon
                          onClick={e => handleRemoveImg(e, i)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div className='my-4'>Add Note</div>
          <div className='mb-3'>
            <div className='py-2 px-3 rubik-font bg-light notes'>
              <span style={{ fontSize: '10px' }}>NOTES</span>
              <div className='d-flex justify-content-between'>
                <input
                  type='text'
                  className='form-control border-0 input-custom py-1'
                  id='note'
                  placeholder='Start typing your comment'
                  value={comment}
                  onChange={e => dispatch(setComment(e.target.value))}
                />
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: '#32323266', paddingLeft: '10px' }}
                  onClick={() => dispatch(setComment(''))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end w-100 pt-5 pb-4'>
          <div className='pe-2'>
            <Button variant='outline-primary' size='lg' onClick={closeModal}>
              Cancel
            </Button>
          </div>
          <div className='ps-2'>
            <Button
              variant='outline-primary'
              type='submit'
              className='ms-2 bg-primary text-light'
              size='lg'
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
