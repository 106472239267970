import { combineReducers } from 'redux';

import { FrameworkReducers } from '@naadi/framework';
import selection from './packing/selection/reducer';
import scan from './packing/scan/reducer';
import itemslist from './packing/itemslist/reducer';
import boxAndItems from './packing/boxAndItems/reducer';
import screenoverlay from './app/screenoverlay/reducer';
import orderArchive from './packing/orderarchive/reducer';
import ordersList from './order/ordersList/reducer';
import appNav from './app/nav/reducer';
import BomDataDuck from './order/bom/bomDataDuck';
import connectedPrinters from './printers/connectedprinters/reducer';
import orderstate from './order/orderstate/orderstate';
import reportfilter from './report/reportfilters';
import reportresult from './report/reportresult';
import userdashboard from './dashboard/userdashboard';
import dashboardstate from './dashboard/dashboardstate';
import shipment from './shipment/reducer';
import { profileReducer } from './profiles/reducers';
import stationstate from './tracktrace/stationstate';
import cgstationstate from './cutguide/cgstationstate';
import dpodstationstate from './digitalpod/dpodstationstate';
import tracktrace from './tracktrace/tracktrace';
import shipmentstate from './shipment/shipmentstate';
import cutguide from './cutguide/cutguide';
import digitalpod from './digitalpod/digitalpod';

import cutsmart from './cutsmart/cutsmartstate';
import cutsmartstateless from './cutsmart/cutsmartstateless';
import rejectionreason from './tracktrace/rejection-reason';

import tracktracestateless from './tracktrace/tracktracestateless';
import pipeline from './tracktrace/pipeline';
import groups from './groups/groups';
import users from './users/users';
import itemsList from './item/itemsList/reducer';

const { busy, lang, user, token } = FrameworkReducers;

export default combineReducers({
  busy,
  lang,
  user,
  token,
  selection,
  scan,
  itemslist,
  itemsList,
  boxAndItems,
  screenoverlay,
  orderArchive,
  ordersList,
  appNav,
  bom_data: BomDataDuck,
  connectedPrinters,
  orderstate,
  reportfilter,
  reportresult,
  userdashboard,
  dashboardstate,
  shipment,
  shipmentstate,
  profile: profileReducer,
  stationstate,
  cgstationstate,
  tracktrace,
  cutsmart,
  cutsmartstateless,
  cutguide,
  tracktracestateless,
  digitalpod,
  dpodstationstate,
  pipeline,
  groups,
  users,
  rejectionreason,
});
